import { useEffect } from "react";
import { useParams } from "react-router";

import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { setUser } from "../../state/reducers/user";

const useSetCpn = () => {
  const stateCpn = useAppSelector((state) => state.user.cpn);
  const urlCpn = useParams<{ cpn: string }>().cpn;
  const cpn = stateCpn || urlCpn;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setUser({ cpn }));
  }, []);
};
export default useSetCpn;
