import { Grid, makeStyles, Paper } from "@material-ui/core";

import { formatToCurrencyWithTwoDecimalPlaces } from "../../util/format";

interface Props {
  balance: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "50%"
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary
  }
}));

const WalletBalancePanel = ({ balance }: Props) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={6}>
        <Paper className={classes.paper} data-testid={"wallet-balance"}>
          Wallet Balance:
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper} data-testid={"wallet-balance-value"}>
          {formatToCurrencyWithTwoDecimalPlaces(balance)}
        </Paper>
      </Grid>
    </>
  );
};

export default WalletBalancePanel;
