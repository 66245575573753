import moment from "moment";
import { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { BankedCode } from "../../clients/services/crm/loyalty/types";
import DailyCodeSummary from "../DailyCodeSummary";

interface Props {
  bankedCodes: BankedCode[];
  onRangeChange: (range: { start: Date; end: Date }) => void;
}

moment.updateLocale("en", {
  week: {
    dow: 1
  }
});

export const CodeCalendarComponent = ({
  bankedCodes,
  onRangeChange
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<moment.Moment | undefined>(
    undefined
  );
  const [showDailySummary, setShowDailySummary] = useState(false);
  const localizer = momentLocalizer(moment);
  if (showDailySummary && selectedDate) {
    return (
      <DailyCodeSummary
        date={selectedDate}
        onClose={() => {
          onRangeChange({
            start: selectedDate.startOf("month").toDate(),
            end: selectedDate.endOf("month").toDate()
          });
          setShowDailySummary(false);
        }}
        onBack={() => {
          const previousDay = selectedDate.subtract(1, "day");
          onRangeChange({
            start: previousDay.toDate(),
            end: previousDay.toDate()
          });
          setSelectedDate(previousDay);
        }}
        onForward={() => {
          const nextDay = selectedDate.add(1, "day");
          onRangeChange({
            start: nextDay.toDate(),
            end: nextDay.toDate()
          });
          setSelectedDate(nextDay);
        }}
      />
    );
  }
  return (
    <div style={{ height: 520 }}>
      <Calendar
        localizer={localizer}
        date={selectedDate?.toDate()}
        selectable={true}
        onSelectEvent={({ start }) => {
          setSelectedDate(moment(start));
          setShowDailySummary(true);
        }} // navigate to code bank view
        events={bankedCodes.map((bankedCode) => {
          const publicationDate = moment(
            bankedCode.publicationDate,
            "YYYY-MM-DD"
          );
          return {
            title: `${bankedCode.paperCode}\n${bankedCode.points} point${
              bankedCode.points !== 1 ? "s" : ""
            } (${bankedCode.paperCodeGroup})\n➩ ${moment
              .tz(bankedCode.bankDateTime, "YYYY-MM-DD'T'HH:mm:SS", "UTC")
              .tz(moment.tz.guess())
              .format("YYYY-MM-DD")}`,
            allDay: true,
            start: publicationDate,
            end: publicationDate
          };
        })}
        views={["month"]}
        onNavigate={(d) => {
          setSelectedDate(moment(d));
          onRangeChange({
            start: moment(d).startOf("month").toDate(),
            end: moment(d).endOf("month").toDate()
          });
        }}
        onDrillDown={(e) => {
          setSelectedDate(moment(e));
          setShowDailySummary(true);
        }}
        onSelectSlot={(s) => {
          setSelectedDate(moment(s.start));
          setShowDailySummary(true);
        }}
      ></Calendar>
    </div>
  );
};
