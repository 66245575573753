import { MenuItem, Select } from "@material-ui/core";
import { AxiosError } from "axios";
import { useState } from "react";
import { useQuery } from "react-query";

import { getCodeBankingAttempts } from "../../clients/services/crm/loyalty";
import { useAppSelector } from "../../state/hooks";
import { ErrorMessage } from "../ErrorMessage";

import CodeBankingAttemptsTable from "./component";

export default () => {
  const cpn = useAppSelector((state) => state.user.cpn);
  const [codeBankingAttemptsLimit, setCodeBankingAttemptsLimit] = useState(20);
  const { data, error } = useQuery(
    ["codeBankingAttempts", cpn, codeBankingAttemptsLimit],
    () =>
      cpn
        ? getCodeBankingAttempts(cpn, codeBankingAttemptsLimit.toString())
        : []
  );

  if (error) {
    return <ErrorMessage axiosErrors={[error as AxiosError]} />;
  }

  return (
    <>
      <div style={{ margin: 10 }}>
        Showing{" "}
        <Select
          fullWidth={false}
          onChange={(e) =>
            setCodeBankingAttemptsLimit(parseInt(e.target.value as string, 10))
          }
          value={codeBankingAttemptsLimit}
          data-testid={"number-of-attempts-select"}
        >
          <MenuItem value={20} data-testid={"twenty-menu-option"}>
            20
          </MenuItem>
          <MenuItem value={100} data-testid={"hundred-menu-option"}>
            100
          </MenuItem>
          <MenuItem value={1000000} data-testid={"all-menu-option"}>
            all
          </MenuItem>
        </Select>{" "}
        latest code banking attempts.
      </div>
      <CodeBankingAttemptsTable codeBankingAttempts={data} />
    </>
  );
};
