import { useEffect, useState } from "react";

import { setupConfig } from "../../config";
import { Config } from "../../config/types";
import { useAppDispatch } from "../../state/hooks";
import { actions } from "../../state/reducers";

const { setConfig } = actions;

const useConfig = () => {
  const [fetchedConfig, setFetchedConfig] = useState<Config | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await setupConfig();
        setFetchedConfig(config);
        dispatch(setConfig(config));
      } catch (e) {
        setError(e as Error);
      }
    };
    fetchConfig();
  }, []);

  return { config: fetchedConfig, error };
};

export default useConfig;
