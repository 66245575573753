import { Grid, TextField } from "@material-ui/core";
import { AxiosError } from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { useBankCode } from "../../hooks/queries/useBankCode";
import { useCheckCode } from "../../hooks/queries/useCheckCode";
import { useGetSupportCode } from "../../hooks/queries/useGetSupportCode";
import { setNotification } from "../../state/reducers/notification";
import { LoadingButton } from "../LoadingButton";

interface Props {
  date: moment.Moment;
}

export default ({ date }: Props) => {
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const {
    isLoading: isLoadingCheckCode,
    error: errorCheckCode,
    refetch: checkCode
  } = useCheckCode(code);
  const { mutate: bankCodeMutation, isLoading: isLoadingBankCode } =
    useBankCode(code, date);
  const { mutate: getSupportCodeMutation, isLoading: isLoadingSupportCode } =
    useGetSupportCode(date);
  const bankCode = async () => {
    const { data } = await checkCode();
    if (data?.publicationDate) {
      if (data.publicationDate === date.format("YYYY-MM-DD")) {
        bankCodeMutation();
        setCode("");
      } else {
        dispatch(
          setNotification({
            message: "This code was not published on the selected date",
            severity: "error"
          })
        );
      }
    } else {
      dispatch(
        setNotification({
          message: (errorCheckCode as AxiosError)?.response?.data.errorMessage,
          severity: "error"
        })
      );
    }
  };
  return (
    <>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          size="small"
          label={"Enter code to bank"}
          value={code}
          onChange={(e) => setCode(e.target.value.toUpperCase())}
          fullWidth={true}
          inputProps={{ "data-testid": "bank-code-input" }}
          onKeyPress={async (e) => {
            if (e.key === "Enter") {
              await bankCode();
            }
          }}
        ></TextField>
      </Grid>
      <Grid item xs={6}>
        <LoadingButton
          loading={isLoadingBankCode || isLoadingCheckCode}
          onClick={bankCode}
          label={"Bank Code"}
          testId={"bank-code-button"}
        />
      </Grid>
      <Grid item xs={6}>
        <LoadingButton
          loading={isLoadingSupportCode}
          onClick={() => {
            getSupportCodeMutation(undefined, {
              onSuccess: (supportCode) => {
                setCode(supportCode.papercode);
              }
            });
          }}
          label={"Generate Code"}
          testId={"generate-code-button"}
        />
      </Grid>
    </>
  );
};
