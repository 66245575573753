import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  TableBody
} from "@material-ui/core";
import moment from "moment-timezone";

import { CodeBankingAttempt } from "../../clients/services/crm/loyalty/types";

interface Props {
  codeBankingAttempts?: CodeBankingAttempt[];
}

const CodeBankingAttemptsTable = ({ codeBankingAttempts }: Props) => (
  <TableContainer component={Paper}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Attempted Code</TableCell>
          <TableCell>Time of Attempt</TableCell>
          <TableCell>Attempt Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {codeBankingAttempts?.map((codeBankingAttempt, index) => (
          <TableRow key={index}>
            <TableCell
              data-testid={`code-banking-${codeBankingAttempt.paperCode}-paper-code`}
            >
              {codeBankingAttempt.paperCode}
            </TableCell>
            <TableCell
              data-testid={`code-banking-${codeBankingAttempt.paperCode}-time-attempt`}
            >
              {moment
                .tz(codeBankingAttempt.attemptDateTime, "UTC")
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD HH:mm:ss")}
            </TableCell>
            <TableCell
              data-testid={`code-banking-${codeBankingAttempt.paperCode}-attempt-status`}
            >
              {codeBankingAttempt.attemptStatus}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
export default CodeBankingAttemptsTable;
