import { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";
import client from "../../base";

import { AppConfig, Order } from "./types";

const baseUrl = staticConfig.servicesUrls?.crmService;

export const getAppConfig = async () => {
  const url = `${baseUrl}/app-config`;

  const response: AxiosResponse<AppConfig> = await client.get(url);
  return response.data;
};

export const getOrders = async (cpn: string) => {
  const url = `${baseUrl}/orders?cpn=${cpn}`;

  const response: AxiosResponse<Order[]> = await client.get(url);
  return response.data;
};

export const refundOrder = async (id: number, cpn: string) => {
  const url = `${baseUrl}/orders/${id}/refund?cpn=${cpn}`;

  const response: AxiosResponse<Order> = await client.put(url, null);
  return response.data;
};
