import { IconButton, Grid, makeStyles, Paper } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import ForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment-timezone";

import { BankedCode } from "../../clients/services/crm/loyalty/types";
import { RaffleTicket } from "../../clients/services/crm/raffle/types";
import BankCode from "../BankCode";

interface Props {
  date: moment.Moment;
  onClose: () => void;
  onBack: () => void;
  onForward: () => void;
  bankedCode?: BankedCode;
  raffleTicket?: RaffleTicket;
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "50%"
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: "50%",
    alignItems: "center"
  },
  titleWithArrows: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

const DailyCodeSummary = ({
  date,
  onClose,
  onBack,
  onForward,
  bankedCode,
  raffleTicket,
  loading
}: Props) => {
  const classes = useStyles();
  const paperCode = loading ? "..." : bankedCode?.paperCode || "N/A";
  let bankDateTime;
  if (loading) {
    bankDateTime = "...";
  } else {
    bankDateTime = bankedCode?.bankDateTime
      ? moment
          .tz(bankedCode?.bankDateTime, "UTC")
          .tz(moment.tz.guess())
          .format("DD MMM YYYY HH:mm:SS")
      : "N/A";
  }
  const paperCodeGroup = loading ? "..." : bankedCode?.paperCodeGroup || "N/A";
  let points;
  if (loading) {
    points = "...";
  } else {
    points = bankedCode
      ? `${bankedCode?.points} point${bankedCode?.points !== 1 ? "s" : ""}`
      : "N/A";
  }
  let hasRaffleTicket;
  if (loading) {
    hasRaffleTicket = "...";
  } else {
    hasRaffleTicket = raffleTicket ? "YES" : "NO";
  }
  return (
    <>
      <div className={classes.header}>
        <div className={classes.titleWithArrows}>
          <IconButton onClick={onBack}>
            <BackIcon />
          </IconButton>
          <h2>Sun Savers Daily Summary {date.format("DD MMM YYYY")}</h2>
          <IconButton onClick={onForward}>
            <ForwardIcon />
          </IconButton>
        </div>
        <div>
          <IconButton onClick={onClose} style={{ marginRight: 10 }}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={6}>
          <Paper className={classes.paper} data-testid={"code"}>
            Code:
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} data-testid={"code-value"}>
            {paperCode}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} data-testid={"bank-date"}>
            Bank Date:
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} data-testid={"bank-date-value"}>
            {bankDateTime}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} data-testid={"code-type"}>
            Code Type:
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} data-testid={"code-type-value"}>
            {paperCodeGroup}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} data-testid={"points"}>
            Points:
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} data-testid={"points-value"}>
            {points}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} data-testid={"raffle-ticket"}>
            Raffle Ticket:
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} data-testid={"raffle-ticket-value"}>
            {hasRaffleTicket}
          </Paper>
        </Grid>
        <BankCode date={date} />
      </Grid>
    </>
  );
};
export default DailyCodeSummary;
