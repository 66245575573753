import { Color } from "@material-ui/lab/Alert";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Notification {
  message?: string;
  severity?: Color;
}

type NotificationState = Notification;

const initialState = {} as NotificationState;

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<Notification>) =>
      action.payload
  }
});

export const { setNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
