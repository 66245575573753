import { CircularProgress } from "@material-ui/core";

import { useGetMerlinOrders } from "../../hooks/queries/useGetMerlinOrders";
import { ErrorMessage } from "../ErrorMessage";

import OrderTable from "./component";

export default () => {
  const { isLoading, data, error } = useGetMerlinOrders();

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage axiosErrors={[error]} />;
  }

  if (data && data.length > 0) {
    return <OrderTable orders={data} />;
  }

  return <ErrorMessage simpleErrors={["No orders found"]} />;
};
