import { DefaultConfig } from "../types";

const packageJson = require("../../../../package.json");

const defaultConfig: DefaultConfig = {
  version: packageJson.version,
  servicesUrls: {
    crmService: "/api"
  }
};

export default defaultConfig;
