import { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";
import client from "../../base";

import { Enrollments } from "./types";

const baseUrl = staticConfig.servicesUrls?.crmService;

export const getEnrollments = async (cpn: string) => {
  const url = `${baseUrl}/auth0/enrollments?cpn=${cpn}`;

  const response: AxiosResponse<Enrollments[]> = await client.get(url);
  return response.data;
};

export const deleteEnrollments = async (cpn: string | undefined) => {
  if (!cpn) return;
  const url = `${baseUrl}/auth0/enrollments?cpn=${cpn}`;

  const response: AxiosResponse<Enrollments[]> = await client.delete(url);
  return response.data;
};

export const deleteSingleEnrollment = async (id: string) => {
  const url = `${baseUrl}/auth0/single/enrollment/?id=${id}`;

  const response: AxiosResponse<Enrollments[]> = await client.delete(url);
  return response.data;
};
