import { Button, CircularProgress } from "@material-ui/core";

interface Props {
  label: string;
  testId: string;
  loading: boolean;
  onClick: () => void;
}

export const LoadingButton = ({ label, testId, loading, onClick }: Props) => (
  <Button
    variant="contained"
    fullWidth={true}
    disabled={loading}
    onClick={onClick}
    data-testid={testId}
  >
    {loading ? <CircularProgress size={24} /> : label}
  </Button>
);
