export interface RegisteredPromotion {
  code: string;
  cpn: string;
  promotionCode: string;
  runningTotal: RunningTotal;
  hasRedeemed: boolean;
  isEligibleToRedeem: boolean;
}

interface RunningTotal {
  balance: number;
  redemptionThreshold: number;
}

export interface Promotion {
  code: string;
  name: string;
  status: Status;
  promotionType: PromotionType;
  codeCollectStartDate: string;
  codeCollectEndDate: string;
  redemptionStartDateTime?: string;
  redemptionEndDateTime: string;
  redemptionThreshold: number;
  allowMultipleRedemption: boolean;
  redeemableEmailTemplate: string | null;
  redeemedEmailTemplate: string | null;
  redemptionAllocation: RedemptionAllocation;
  soldOut: boolean;
}

export interface PromotionRedemption {
  code: string;
  cpn: string;
  promotionCode: string;
  createdDatetime: string;
}

export interface RegisteredPromotionInfo {
  optedIn: boolean;
  codesCollected?: number;
  hasRedeemed?: boolean;
  isEligibleToRedeem?: boolean;
  redeemedDateTime?: string;
}

export type PromotionFullInfo = Promotion &
  RegisteredPromotionInfo & {
    cpn?: string;
  };

interface RedemptionAllocation {
  total: number;
  remaining: number;
}

export enum Status {
  ACTIVE = "Active",
  EXPIRED = "Expired"
}

export enum OfferRedemptionMechanic {
  INTERNAL = "Internal",
  EXTERNAL = "External site"
}

export enum PromotionType {
  COMPETITION = "COMPETITION",
  NONCODE = "NONCODE",
  REGULAR = "REGULAR",
  DONATION = "DONATION",
  WALLET = "WALLET"
}
