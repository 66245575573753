import { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";
import client from "../../base";

import { Membership } from "./types";

const baseUrl = staticConfig.servicesUrls?.crmService;

export const getMemberships = async (cpn: string) => {
  const url = `${baseUrl}/customer/memberships?cpn=${cpn}`;

  const response: AxiosResponse<Membership[]> = await client.get(url);
  return response.data;
};

export const blacklistCustomer = async (cpn: string, blacklisted: boolean) => {
  const url = `${baseUrl}/customer/blacklist?cpn=${cpn}`;
  const payload = { blacklisted };
  const response: AxiosResponse<Membership> = await client.put(url, payload);
  return response.data;
};
