import numeral from "numeral";
import "numeral/locales/en-gb";

// We have to set the locale to en-gb so that whenever we use $ symbol in our
// format patterns, it will be converted to the en-gb locale version which is a £
// This is the only way to get the £ symbol to be used in the numeral library
numeral.locale("en-gb");

export const formatToCurrencyWithTwoDecimalPlaces = (amount: number): string =>
  // The $ symbol will become a £ symbol in the output because we are using the en-gb locale
  numeral(amount).format("$0,0.00");
