import axios, { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";

import { AppConfig } from "./types";

const baseUrl = staticConfig.servicesUrls?.crmService;

export const getAppConfig = async () => {
  const url = `${baseUrl}/app-config`;

  const response: AxiosResponse<AppConfig> = await axios.get(url);
  return response.data;
};
