import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { SaversError } from "../../clients/services/crm/error/types";
import { getRaffleTickets } from "../../clients/services/crm/raffle";
import { RaffleTicket } from "../../clients/services/crm/raffle/types";
import { useAppSelector } from "../../state/hooks";

export const useGetRaffleTickets = (date: moment.Moment) => {
  const cpn = useAppSelector((state) => state.user.cpn);
  return useQuery<RaffleTicket[], AxiosError<SaversError>>(
    ["raffleTickets", cpn, date],
    () =>
      cpn
        ? getRaffleTickets(
            cpn,
            date.format("YYYY-MM-DD"),
            date.format("YYYY-MM-DD")
          )
        : []
  );
};
