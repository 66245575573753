import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { SaversError } from "../../clients/services/crm/error/types";
import { getBankedCodes } from "../../clients/services/crm/loyalty";
import { BankedCode } from "../../clients/services/crm/loyalty/types";
import { useAppSelector } from "../../state/hooks";

export const useGetBankedCodes = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  const cpn = useAppSelector((state) => state.user.cpn);
  return useQuery<BankedCode[], AxiosError<SaversError>>(
    ["bankedCodes", cpn, startDate, endDate],
    () =>
      cpn
        ? getBankedCodes(
            cpn,
            startDate.format("YYYY-MM-DD"),
            endDate.format("YYYY-MM-DD")
          )
        : []
  );
};
