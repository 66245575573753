import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useHistory } from "react-router-dom";

import { useAppSelector } from "../../state/hooks";

const useAuth = () => {
  const auth = useAppSelector((state) => state.config?.auth);
  const history = useHistory();

  if (!auth) return null;

  const authConfig = {
    clientId: auth.clientId,
    issuer: auth.issuer,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ["openid", "profile", "email", "groups"],
    pkce: true
  };

  const oktaAuth = new OktaAuth(authConfig);
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return { oktaAuth, restoreOriginalUri };
};

export default useAuth;
