import { Button, CircularProgress } from "@material-ui/core";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import BlockIcon from "@material-ui/icons/Block";
import { AxiosError } from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import {
  blacklistCustomer,
  getMemberships
} from "../../clients/services/crm/customer";
import { useAppSelector } from "../../state/hooks";
import { setNotification } from "../../state/reducers/notification";
import ConfirmationDialog from "../ConfirmationDialog";
import { ErrorMessage } from "../ErrorMessage";

export default () => {
  const cpn = useAppSelector((state) => state.user.cpn);
  const { isLoading, data, error } = useQuery(["getMemberships", cpn], () =>
    cpn ? getMemberships(cpn) : []
  );
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate: blacklistCustomerMutation, isLoading: isBlacklisting } =
    useMutation(
      (blacklistedStatus: boolean) =>
        blacklistCustomer(cpn!, blacklistedStatus),
      {
        onSuccess: () => {
          // Invalidate and refetch
          queryClient.invalidateQueries(["getMemberships", cpn]);
          dispatch(
            setNotification({
              message: "Customer blacklisted status updated successfully",
              severity: "success"
            })
          );
        },
        onError: (responseError: AxiosError) => {
          dispatch(
            setNotification({
              message:
                responseError.response?.status === 401
                  ? responseError.response.data.errorMessage
                  : responseError.message,
              severity: "error"
            })
          );
        }
      }
    );

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage axiosErrors={[error as AxiosError]} />;
  }

  if (data && data.length > 0) {
    const isCustomerBlacklisted =
      data.filter(({ blacklisted }) => blacklisted).length > 0;
    return (
      <div>
        <ConfirmationDialog
          title="Manage Blacklist Status"
          description={`Are you sure you would like to ${
            isCustomerBlacklisted ? "unblacklist" : "blacklist"
          } this customer?`}
          actionLabel={isCustomerBlacklisted ? "Unblacklist" : "Blacklist"}
          action={async () => {
            await blacklistCustomerMutation(!isCustomerBlacklisted);
          }}
          isLoading={isBlacklisting}
          testIdPrefix={`blacklist-customer`}
        >
          {(triggerAction) => (
            <Button
              startIcon={
                isCustomerBlacklisted ? <AccessibilityNewIcon /> : <BlockIcon />
              }
              variant="contained"
              disabled={isBlacklisting}
              onClick={triggerAction}
              style={{ marginBottom: 8 }}
              data-testid={`blacklist-button`}
            >
              {isCustomerBlacklisted ? "Unblacklist" : "Blacklist"}
            </Button>
          )}
        </ConfirmationDialog>
      </div>
    );
  }

  return <ErrorMessage simpleErrors={["No customer details found"]} />;
};
