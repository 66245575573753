import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Security = {
  token?: string;
};
type SecurityState = Security | null;

const initialState = {} as SecurityState;

const securitySlice = createSlice({
  name: "security",
  initialState,
  reducers: {
    setSecurity: (state, action: PayloadAction<SecurityState>) => action.payload
  }
});

export const { setSecurity } = securitySlice.actions;

export default securitySlice.reducer;
