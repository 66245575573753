import { CircularProgress, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { AxiosError } from "axios";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-query";

import { SaversError } from "../../clients/services/crm/error/types";
import {
  getRegisteredPromotions,
  getPromotions,
  getPromotionRedemptions
} from "../../clients/services/crm/promotion";
import {
  Promotion,
  PromotionFullInfo,
  PromotionRedemption,
  RegisteredPromotion,
  RegisteredPromotionInfo
} from "../../clients/services/crm/promotion/types";
import { useAppSelector } from "../../state/hooks";
import { PAGE_SIZE } from "../../util/constants";
import { ErrorMessage } from "../ErrorMessage";

import PromotionsTable from "./component";

const useStyles = makeStyles(() => ({
  pagesMenu: {
    paddingBottom: 15,
    width: "100%",
    display: "flex",
    justifyContent: "center"
  }
}));

export default () => {
  const cpn = useAppSelector((state) => state.user.cpn);
  const [page, setPage] = React.useState(1);
  const classes = useStyles();

  const {
    isLoading: isLoadingPromotionRedemptions,
    data: promotionRedemptions,
    error: errorPromotionRedemptions
  } = useQuery<PromotionRedemption[], AxiosError<SaversError>>(
    ["promotionRedemptions", cpn],
    () => (cpn ? getPromotionRedemptions(cpn) : [])
  );

  const {
    isLoading: isLoadingRegisteredPromotions,
    data: registeredPromotions,
    error: errorRegisteredPromotions
  } = useQuery<RegisteredPromotion[], AxiosError<SaversError>>(
    ["registeredPromotions", cpn],
    () => (cpn ? getRegisteredPromotions(cpn) : [])
  );

  const {
    isLoading: isLoadingPromotions,
    data: promotions,
    error: errorPromotions
  } = useQuery<Promotion[], AxiosError<SaversError>>(["promotions"], () =>
    getPromotions()
  );

  const promotionsFullInfo: PromotionFullInfo[] = promotions
    ? promotions.map((promotion) => {
        const registeredPromotion: RegisteredPromotion | undefined =
          registeredPromotions?.find(
            (registeredPromo) =>
              registeredPromo.promotionCode === promotion.code
          );

        const promotionRedemption: PromotionRedemption | undefined =
          registeredPromotion?.hasRedeemed
            ? promotionRedemptions?.find(
                (promoRedemption) =>
                  promoRedemption.promotionCode === promotion.code
              )
            : undefined;

        const registeredPromotionInfo: RegisteredPromotionInfo =
          registeredPromotion
            ? {
                optedIn: true,
                codesCollected: registeredPromotion.runningTotal.balance,
                hasRedeemed: registeredPromotion.hasRedeemed,
                isEligibleToRedeem: registeredPromotion.isEligibleToRedeem,
                redeemedDateTime: promotionRedemption?.createdDatetime
              }
            : {
                optedIn: false
              };

        return {
          cpn,
          ...promotion,
          ...registeredPromotionInfo
        };
      })
    : [];

  if (!cpn) {
    return <div>No promotions found</div>;
  }

  if (
    isLoadingRegisteredPromotions ||
    isLoadingPromotions ||
    isLoadingPromotionRedemptions
  ) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (
    errorRegisteredPromotions ||
    errorPromotions ||
    errorPromotionRedemptions
  ) {
    return (
      <ErrorMessage
        axiosErrors={[
          errorRegisteredPromotions,
          errorPromotions,
          errorPromotionRedemptions
        ]}
      />
    );
  }

  if (promotionsFullInfo && promotionsFullInfo.length > 0) {
    const sortedPromotions = _.orderBy(promotionsFullInfo, ["status"], ["asc"]);

    const pageResults = sortedPromotions.slice(
      (page - 1) * PAGE_SIZE,
      page * PAGE_SIZE
    );

    return (
      <>
        {sortedPromotions.length > PAGE_SIZE && (
          <div className={classes.pagesMenu}>
            <Pagination
              count={_.ceil(sortedPromotions.length / PAGE_SIZE)}
              page={page}
              onChange={(event, value) => {
                setPage(value);
              }}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        )}
        <PromotionsTable promotions={pageResults} />
      </>
    );
  }

  return <div>No promotions found</div>;
};
