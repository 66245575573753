import { CircularProgress } from "@material-ui/core";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Switch } from "react-router-dom";

import { Notification } from "./components/Notification";
import useAuth from "./hooks/useAuth";
import useConfig from "./hooks/useConfig";
import Home from "./pages/Home";
import { useAppSelector } from "./state/hooks";
import "./App.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

const App = () => {
  useConfig();
  const config = useAppSelector((state) => state.config);
  const auth = useAuth();

  if (!config || !auth) {
    return (
      <div className="App">
        <header className="App-header">
          <CircularProgress data-testid={"loading"} />
        </header>
      </div>
    );
  }

  const { oktaAuth, restoreOriginalUri } = auth;
  return (
    <div className="App">
      <header className="App-header">
        <div data-testid="root">
          <QueryClientProvider client={queryClient}>
            <Security
              oktaAuth={oktaAuth}
              restoreOriginalUri={restoreOriginalUri}
            >
              <Switch>
                <Route exact path={"/"} component={Home} />
                <SecureRoute exact path={"/:cpn"} component={Home} />
                <Route path={"/login/callback"} component={LoginCallback} />
              </Switch>
            </Security>
          </QueryClientProvider>
          <Notification />
        </div>
      </header>
    </div>
  );
};

export default App;
