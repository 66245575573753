import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  TableBody,
  Button,
  FormControl,
  InputAdornment,
  Input
} from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DeleteIcon from "@material-ui/icons/Delete";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import {
  deletePayoutMethod,
  createDebitTransaction
} from "../../clients/services/crm/wallet";
import { PayoutMethod } from "../../clients/services/crm/wallet/types";
import { setNotification } from "../../state/reducers/notification";
import ConfirmationDialog from "../ConfirmationDialog";

interface Props {
  cpn: string;
  balance: number;
  methods: PayoutMethod[];
  refetchMethods: () => void;
  refetchBalance: () => void;
}

const PayoutMethodsTable = ({
  cpn,
  balance,
  methods,
  refetchMethods,
  refetchBalance
}: Props) => {
  const [cashoutAmount, setCashoutAmount] = useState(balance.toString());
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  useEffect(() => {
    setCashoutAmount(balance.toString());
  }, [balance]);

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Delete Method</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Friendly Name</TableCell>
            <TableCell>Bank Account Name</TableCell>
            <TableCell>Cashout Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {methods.map((method) => {
            const {
              mutate: deletePayoutMethodMutation,
              isLoading: isDeletionInProgress
            } = useMutation(() => deletePayoutMethod(cpn!, method.code), {
              onSuccess: async () => {
                dispatch(
                  setNotification({
                    message: `Payout method "${method.bankAccountName}" deleted successfully`,
                    severity: "success"
                  })
                );

                // Invalidate and refetch
                await queryClient.invalidateQueries(["payoutMethods", cpn]);
                await refetchMethods();
              },
              onError: (responseError: AxiosError) => {
                dispatch(
                  setNotification({
                    message:
                      responseError.response?.data?.errorMessage ||
                      responseError.message,
                    severity: "error"
                  })
                );
                setCashoutAmount(balance.toString());
              }
            });

            const { mutate: cashoutMutation, isLoading: isCashoutInProgress } =
              useMutation(
                (amount: string) =>
                  createDebitTransaction(cpn!, amount, method.code),
                {
                  onSuccess: async () => {
                    dispatch(
                      setNotification({
                        message: `£${cashoutAmount} cashed out to "${method.bankAccountName}" successfully`,
                        severity: "success"
                      })
                    );

                    // Invalidate and refetch
                    await queryClient.invalidateQueries(["walletBalance", cpn]);
                    await refetchBalance();
                  },
                  onError: (responseError: AxiosError) => {
                    dispatch(
                      setNotification({
                        message:
                          responseError.response?.data?.errorMessage ||
                          responseError.message,
                        severity: "error"
                      })
                    );
                    setCashoutAmount(balance.toString());
                  }
                }
              );

            return (
              <TableRow key={`payoutmethod-${method.code}`}>
                <TableCell>
                  <ConfirmationDialog
                    title="Delete Payout Method"
                    description={`Are you sure you would like to delete "${method.bankAccountName}" payout method?`}
                    actionLabel="Delete Method"
                    action={deletePayoutMethodMutation}
                    isLoading={isDeletionInProgress}
                    testIdPrefix={`delete-method-${method.code}`}
                  >
                    {(triggerAction) => (
                      <Button
                        startIcon={<DeleteIcon />}
                        size="small"
                        onClick={triggerAction}
                        data-testid={`delete-method-${method.code}-trigger`}
                      >
                        Delete
                      </Button>
                    )}
                  </ConfirmationDialog>
                </TableCell>
                <TableCell data-testid={`payout-method-${method.code}`}>
                  {method.code}
                </TableCell>
                <TableCell data-testid={`payout-method-${method.code}-type`}>
                  {method.type}
                </TableCell>
                <TableCell
                  data-testid={`payout-method-${method.code}-friendly-name`}
                >
                  {method.friendlyName}
                </TableCell>
                <TableCell
                  data-testid={`payout-method-${method.code}-bank-account`}
                >
                  {method.bankAccountName}
                </TableCell>
                <TableCell>
                  <FormControl>
                    <Input
                      style={{ backgroundColor: "white" }}
                      value={cashoutAmount}
                      onChange={(e) => setCashoutAmount(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start">£</InputAdornment>
                      }
                      inputProps={{
                        "data-testid": "cashout-amount"
                      }}
                    />
                  </FormControl>
                  <ConfirmationDialog
                    title={`Cashout £${cashoutAmount}`}
                    description={`Are you sure you would like to cashout £${cashoutAmount} to ${method.bankAccountName}?`}
                    actionLabel="Proceed"
                    action={() => cashoutMutation(cashoutAmount)}
                    isLoading={isCashoutInProgress}
                    testIdPrefix={`cashout-${method.code}`}
                  >
                    {(triggerAction) => (
                      <Button
                        startIcon={<AccountBalanceIcon />}
                        size="small"
                        onClick={triggerAction}
                        data-testid={`cashout-${method.code}-trigger`}
                      >
                        Cashout
                      </Button>
                    )}
                  </ConfirmationDialog>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PayoutMethodsTable;
