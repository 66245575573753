import axios from "axios";

import store from "../../state/store";

const instance = axios.create();

instance.defaults.timeout = 10000;
interface Headers {
  Authorization?: string;
  "X-Mock-Mode"?: string;
}

instance.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    const accessToken = state.security?.token;
    const useMockMode = state.mock?.enabled;
    const headers: Headers = {};

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    if (useMockMode) {
      headers["X-Mock-Mode"] = "e2e";
    }
    // eslint-disable-next-line no-param-reassign
    config.headers = headers;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
