import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Config } from "../../config/types";

type ConfigState = Config | null;

const initialState = null as ConfigState;

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<Config>) => action.payload
  }
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
