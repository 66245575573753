import { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";
import client from "../../base";

import { Promotion, PromotionRedemption, RegisteredPromotion } from "./types";

const baseUrl = staticConfig.servicesUrls?.crmService;

export const getRegisteredPromotions = async (cpn: string) => {
  const url = `${baseUrl}/promotion/registered-promotions?cpn=${cpn}`;

  const response: AxiosResponse<RegisteredPromotion[]> = await client.get(url);
  return response.data;
};

export const getPromotions = async (codes?: string) => {
  const url = codes
    ? `${baseUrl}/promotion/promotions?codes=${codes}`
    : `${baseUrl}/promotion/promotions`;

  const response: AxiosResponse<Promotion[]> = await client.get(url);
  return response.data;
};

export const getPromotionRedemptions = async (cpn: string) => {
  const url = `${baseUrl}/promotion/promotion-redemptions?cpn=${cpn}`;

  const response: AxiosResponse<PromotionRedemption[]> = await client.get(url);
  return response.data;
};

export const optInPromotion = async (cpn: string, promotionCode: string) => {
  const url = `${baseUrl}/promotion/registered-promotions?cpn=${cpn}`;
  const payload = { promotionCode };

  const response: AxiosResponse<RegisteredPromotion> = await client.post(
    url,
    payload
  );
  return response.data;
};
