import { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";
import client from "../../base";

import {
  PayoutMethod,
  TransactionResponse,
  WalletTransaction,
  WalletBalance
} from "./types";

const baseUrl = staticConfig.servicesUrls?.crmService;

export const getWalletBalance = async (cpn: string) => {
  const url = `${baseUrl}/wallet/balance?cpn=${cpn}`;

  const response: AxiosResponse<WalletBalance> = await client.get(url);
  return response.data.balance;
};

export const getWalletTransactions = async (cpn: string) => {
  const url = `${baseUrl}/wallet/transactions?cpn=${cpn}`;

  const response: AxiosResponse<WalletTransaction[]> = await client.get(url);
  return response.data;
};

export const getPayoutMethods = async (cpn: string) => {
  const url = `${baseUrl}/wallet/payout-methods?cpn=${cpn}`;

  const response: AxiosResponse<PayoutMethod[]> = await client.get(url);
  return response.data;
};

export const createPayoutMethod = async (
  cpn: string | undefined,
  body: {
    bankAccountName: string;
    bankAccountNumber: string;
    bankSortCode: string;
  }
) => {
  const url = `${baseUrl}/wallet/payout-methods?cpn=${cpn}`;
  const response: AxiosResponse<PayoutMethod[]> = await client.post(url, body);
  return response.data;
};

export const createDebitTransaction = async (
  cpn: string,
  amount: string,
  payoutMethod: string
) => {
  const url = `${baseUrl}/wallet/debits?cpn=${cpn}`;
  const payload = { amount, payoutMethod };

  const response: AxiosResponse<TransactionResponse> = await client.post(
    url,
    payload
  );
  return response.data;
};

export const deletePayoutMethod = async (cpn: string, code: string) => {
  const url = `${baseUrl}/wallet/payout-methods/${code}?cpn=${cpn}`;

  const response: AxiosResponse<null> = await client.delete(url);
  return response.data;
};
