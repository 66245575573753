import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { createPayoutMethod } from "../../clients/services/crm/wallet";
import { useAppSelector } from "../../state/hooks";
import { setNotification } from "../../state/reducers/notification";

export const useCreatePayoutMethod = (
  bankAccountName: string,
  bankAccountNumber: string,
  bankSortCode: string
) => {
  const cpn = useAppSelector((state) => state.user.cpn);
  const body = { bankAccountName, bankAccountNumber, bankSortCode };
  const dispatch = useDispatch();

  return useMutation(
    "createPayoutMethod",
    () => createPayoutMethod(cpn, body),
    {
      onSuccess: () => {
        dispatch(
          setNotification({
            message: "Payoout method added successfully.",
            severity: "success"
          })
        );
      },
      onError: (error: AxiosError) => {
        dispatch(
          setNotification({
            message: error?.response?.data.errorMessage,
            severity: "error"
          })
        );
      }
    }
  );
};
