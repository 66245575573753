import merge from "lodash/merge";

import { isLocalEnvironment } from "../../util/environment";

import defaultConfig from "./environments/default";
import localConfig from "./environments/local";
import { StaticConfig } from "./types";

const environmentConfig = isLocalEnvironment() ? localConfig : {};

const staticConfig: StaticConfig = merge({}, defaultConfig, environmentConfig);

export default staticConfig;
