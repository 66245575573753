import { Grid, makeStyles, Paper } from "@material-ui/core";

import { CodeBalance } from "../../clients/services/crm/loyalty/types";
import { CurrentMonthTicketCount } from "../../clients/services/crm/raffle/types";

interface Props {
  codeBalance?: CodeBalance;
  raffleTicketCount?: CurrentMonthTicketCount;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "50%"
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary
  }
}));

const CodeBalancePanel = ({ codeBalance, raffleTicketCount }: Props) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={6}>
        <Paper className={classes.paper}>Code Balance:</Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper} data-testid={"code-balance"}>
          {codeBalance?.unredeemedBalance ?? "N/A"}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper}>Monthly Raffle Ticket Balance:</Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper
          className={classes.paper}
          data-testid={"monthly-raffle-ticket-balance"}
        >
          {raffleTicketCount?.enteredRaffle
            ? raffleTicketCount?.ticketCount
            : "Not opted in"}
        </Paper>
      </Grid>
    </Grid>
  );
};
export default CodeBalancePanel;
