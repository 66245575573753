import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  TableBody,
  CircularProgress
} from "@material-ui/core";
import { AxiosError } from "axios";
import moment from "moment-timezone";
import { useQuery } from "react-query";

import { SaversError } from "../../clients/services/crm/error/types";
import { getWalletTransactions } from "../../clients/services/crm/wallet";
import { WalletTransaction } from "../../clients/services/crm/wallet/types";
import { formatToCurrencyWithTwoDecimalPlaces } from "../../util/format";
import { ErrorMessage } from "../ErrorMessage";

interface Props {
  cpn: string;
}

const WalletTransactionsTable = ({ cpn }: Props) => {
  const { isLoading, data, error } = useQuery<
    WalletTransaction[],
    AxiosError<SaversError>
  >(["getWalletTransactions", cpn], () =>
    cpn ? getWalletTransactions(cpn) : []
  );

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage axiosErrors={[error]} />;
  }

  if (data && data.length > 0) {
    return (
      <>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell data-testid={"table-head-code"}>Code</TableCell>
                <TableCell>Activity Type</TableCell>
                <TableCell>Reference</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Payment Method Code</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((transaction: WalletTransaction) => (
                <TableRow key={`walletTransaction-${transaction.reference}`}>
                  <TableCell data-testid={"table-body-code"}>
                    {transaction.code}
                  </TableCell>
                  <TableCell>{transaction.activityCode}</TableCell>
                  <TableCell>{transaction.reference || "-"}</TableCell>
                  <TableCell>{transaction.type}</TableCell>
                  <TableCell>
                    {formatToCurrencyWithTwoDecimalPlaces(transaction.amount)}
                  </TableCell>
                  <TableCell>{transaction.paymentMethodCode || "-"}</TableCell>
                  <TableCell>{transaction.status}</TableCell>
                  <TableCell>
                    {moment
                      .tz(transaction.createdDate, "UTC")
                      .tz(moment.tz.guess())
                      .format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <ErrorMessage simpleErrors={["No wallet transactions found"]} />;
    </>
  );
};

export default WalletTransactionsTable;
