import { Alert } from "@material-ui/lab";
import { AxiosError } from "axios";

import { SaversError } from "../../clients/services/crm/error/types";

interface Props {
  axiosErrors?: (AxiosError<SaversError> | null)[];
  simpleErrors?: string[];
}

export const ErrorMessage = ({ axiosErrors, simpleErrors }: Props) => (
  <>
    {axiosErrors
      ?.filter((error) => error)
      .map((error, key) => (
        <Alert
          severity="error"
          key={error?.response?.data.errorMessage}
          data-testid={`error-message-${key}`}
        >
          {[error?.response?.data.errorCode, error?.response?.data.errorMessage]
            .filter((word) => word)
            .join(": ")}
        </Alert>
      ))}
    {simpleErrors
      ?.filter((error) => error)
      .map((error) => (
        <Alert severity="error" key={error} data-testid={`error-message`}>
          {error}
        </Alert>
      ))}
  </>
);
