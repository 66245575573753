export interface AppConfig {
  environment: string;
  optimizelySdkKey: string;
  auth: {
    clientId: string;
    issuer: string;
  };
}
export enum AdmissionType {
  OPEN = "OPEN",
  DATED = "DATED",
  TIMED = "TIMED"
}

export enum Status {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  REFUNDED = "REFUNDED",
  FAILED_REFUND = "FAILED_REFUND"
}
export interface Ticket {
  id: number;
  ticketNumber: string;
}
export interface OrderLine {
  id: number;
  merlinEventId: string;
  quantity: number;
  startDate: string;
  startTime: {
    hour: number;
    minute: number;
    nano: number;
    second: number;
  };
  tickets: Ticket[];
  variant: Variant;
}
export interface Variant {
  description: string;
  id: number;
  mandatoryQuantity: number;
  maxOrderLimit: number;
  merlinVariantId: string;
  minOrderLimit: number;
  price: number;
}
export interface Product {
  admissionType: AdmissionType;
  bookingFee?: {
    amount: number;
  };
  title: string;
}
interface Reservation {
  merlinReservationId: string;
  reservationTimeoutDate: string;
  reservationTimeoutInMilli: number;
}

export enum PaymentType {
  WALLET = "WALLET",
  STRIPE = "STRIPE"
}

export enum PaymentStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  COMPLETE = "COMPLETE",
  REFUNDED = "REFUNDED",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
  FAILED_REFUND = "FAILED_REFUND"
}

interface Payment {
  id: number;
  type: PaymentType;
  amount: number;
  status: PaymentStatus;
  reference: string;
  successUrl?: string;
  cancelUrl?: string;
}

export interface Order {
  attraction: {
    description: string;
    id: number;
  };
  cpn: string;
  externalOrderId: string;
  id: number;
  merlinOrderId: string;
  merlinStatus: string;
  orderDate: string;
  orderLines: OrderLine[];
  orderPayments: Payment[];
  orderTotal: number;
  product: Product;
  reservation?: Reservation;
  status: Status;
}
