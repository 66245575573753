import { Button, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { AxiosError } from "axios";
import { useState } from "react";
import { useQuery } from "react-query";

import { SaversError } from "../../clients/services/crm/error/types";
import { getWalletBalance } from "../../clients/services/crm/wallet";
import { useAppSelector } from "../../state/hooks";
import { ErrorMessage } from "../ErrorMessage";

import WalletBalancePanel from "./balance";
import WalletTransactionsTable from "./transactions";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    maxWidth: "50%"
  }
}));

export default () => {
  const classes = useStyles();
  const [transactionsShown, showTransactions] = useState(false);
  const cpn = useAppSelector((state) => state.user.cpn);
  const { isLoading, data, error } = useQuery<number, AxiosError<SaversError>>(
    ["getWalletBalance", cpn],
    () => (cpn ? getWalletBalance(cpn) : 0)
  );

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  if (error) {
    return <ErrorMessage axiosErrors={[error]} />;
  }
  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        <WalletBalancePanel balance={data!} />
        <Grid item xs={6}>
          <Button
            data-testid={"transactions-btn"}
            variant="outlined"
            onClick={() => {
              showTransactions(!transactionsShown);
            }}
            fullWidth={true}
            style={{ marginBottom: 8 }}
          >
            {`${transactionsShown ? "Hide" : "Show"} Transactions`}
          </Button>
        </Grid>
      </Grid>
      {transactionsShown && <WalletTransactionsTable cpn={cpn!} />}
    </>
  );
};
