import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { getSupportCode } from "../../clients/services/crm/loyalty";
import { setNotification } from "../../state/reducers/notification";

export const useGetSupportCode = (date: moment.Moment) => {
  const dispatch = useDispatch();

  return useMutation(
    "getSupportCode",
    () => getSupportCode(date.format("YYYY-MM-DD")),
    {
      onSuccess: () => {
        dispatch(
          setNotification({
            message: "Code generated successfully.",
            severity: "success"
          })
        );
      },
      onError: (error: AxiosError) => {
        dispatch(
          setNotification({
            message: error?.response?.data.errorMessage,
            severity: "error"
          })
        );
      }
    }
  );
};
