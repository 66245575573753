import { Button, CircularProgress } from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";
import { useState } from "react";

const Logout = () => {
  const { oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const logout = async () => {
    setLoading(true);
    return oktaAuth.signOut();
  };

  return (
    <Button
      onClick={logout}
      disabled={loading}
      color={"inherit"}
      style={{ minWidth: 100, color: "white" }}
      data-testid="logout"
    >
      {loading ? (
        <CircularProgress
          size={24}
          data-testid={"loading"}
          style={{ color: "white" }}
        />
      ) : (
        "Logout"
      )}
    </Button>
  );
};

export default Logout;
