import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  TableBody,
  makeStyles,
  createStyles,
  Button,
  Grid,
  Checkbox
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { AxiosError } from "axios";
import moment from "moment";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import {
  deleteEnrollments,
  deleteSingleEnrollment
} from "../../clients/services/crm/auth0";
import { Enrollments } from "../../clients/services/crm/auth0/types";
import { useAppSelector } from "../../state/hooks";
import { setNotification } from "../../state/reducers/notification";
import ConfirmationDialog from "../ConfirmationDialog";

interface Props {
  enrollments: Enrollments[];
  refetchMethods: () => void;
}
const useStyles = makeStyles(() =>
  createStyles({
    resetButton: {
      marginBottom: "12px"
    }
  })
);

const MfaTable = ({ enrollments, refetchMethods }: Props) => {
  const cpn = useAppSelector((state) => state.user.cpn);
  const dispatch = useDispatch();

  const { mutate: deleteEnrollmentMutation, isLoading: isDeletionInProgress } =
    useMutation(() => deleteEnrollments(cpn), {
      onSuccess: async () => {
        dispatch(
          setNotification({
            message: `Enrollment methods deleted successfully`,
            severity: "success"
          })
        );

        await refetchMethods();
      },
      onError: (responseError: AxiosError) => {
        dispatch(
          setNotification({
            message:
              responseError.response?.data?.errorMessage ||
              responseError.message,
            severity: "error"
          })
        );
      }
    });

  const {
    mutate: deleteSingleEnrollmentMutation,
    isLoading: isDeletionSEProgress
  } = useMutation((id: string) => deleteSingleEnrollment(id), {
    onSuccess: async () => {
      dispatch(
        setNotification({
          message: `Enrollment method deleted successfully`,
          severity: "success"
        })
      );

      await refetchMethods();
    },
    onError: (responseError: AxiosError) => {
      dispatch(
        setNotification({
          message:
            responseError.response?.data?.errorMessage || responseError.message,
          severity: "error"
        })
      );
    }
  });

  const classes = useStyles();
  return (
    <div data-testid={"enrollmentContainer"}>
      <ConfirmationDialog
        title="Delete Authentication Method"
        description={`Are you sure you would like to reset all authentication methods?`}
        actionLabel="Delete MFA Method"
        action={deleteEnrollmentMutation}
        isLoading={isDeletionInProgress}
        testIdPrefix={`reset-mfa-method`}
      >
        {(triggerAction) => (
          <Grid item xs={6} className={classes.resetButton}>
            <Button
              endIcon={<DeleteIcon />}
              size="small"
              onClick={triggerAction}
              data-testid={`reset-mfa-method-trigger`}
              variant="contained"
            >
              Reset All Mfa
            </Button>
          </Grid>
        )}
      </ConfirmationDialog>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Confirmed</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Last Auth At</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Enrolled At</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {enrollments.map((enrollment) => (
              <TableRow key={`enrollment-${enrollment.id}`}>
                <TableCell>{enrollment.type}</TableCell>
                <TableCell>
                  <Checkbox checked={enrollment.confirmed} disabled />
                </TableCell>
                <TableCell>
                  {moment(enrollment.created_at).format("YYYY-MM-DD HH:mm")}
                </TableCell>
                <TableCell>
                  {moment(enrollment.last_auth_at).format("YYYY-MM-DD HH:mm")}
                </TableCell>
                <TableCell>{enrollment.name}</TableCell>
                <TableCell>
                  {moment(enrollment.enrolled_at).format("YYYY-MM-DD HH:mm")}
                </TableCell>
                <TableCell>
                  <ConfirmationDialog
                    title="Delete Authentication Method"
                    description={`Are you sure you would like to delete authentication method with type: ${
                      enrollment.type
                    } and creation date: ${moment(enrollment.created_at).format(
                      "YYYY-MM-DD HH:mm"
                    )} ?`}
                    actionLabel="Delete MFA Method"
                    action={async () => {
                      deleteSingleEnrollmentMutation(enrollment.id);
                    }}
                    isLoading={isDeletionSEProgress}
                    testIdPrefix={`delete-method-${enrollment.id}`}
                  >
                    {(triggerAction) => (
                      <Button
                        startIcon={<DeleteIcon />}
                        size="small"
                        onClick={triggerAction}
                        data-testid={`delete-method-${enrollment.id}-trigger`}
                      >
                        Delete
                      </Button>
                    )}
                  </ConfirmationDialog>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default MfaTable;
