import { Grid, makeStyles, Paper } from "@material-ui/core";

import { CheckCodeResponse } from "../../clients/services/crm/loyalty/types";

interface Props {
  checkCodeResponse?: CheckCodeResponse;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "50%"
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary
  }
}));

const CodeBalancePanel = ({ checkCodeResponse }: Props) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={6}>
        <Paper className={classes.paper}>Status:</Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper} data-testid={"check-code-status"}>
          {checkCodeResponse?.status ?? "N/A"}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper}>Publication Date:</Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper
          className={classes.paper}
          data-testid={"check-code-publication-date"}
        >
          {checkCodeResponse?.publicationDate ?? "N/A"}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper}>Banked By:</Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper} data-testid={"check-code-banked-by"}>
          {checkCodeResponse?.cpn ?? "N/A"}
        </Paper>
      </Grid>
    </Grid>
  );
};
export default CodeBalancePanel;
