import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface User {
  cpn?: string;
}

type UserState = User;

const initialState = {} as UserState;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => action.payload
  }
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
