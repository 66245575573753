import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { SaversError } from "../../clients/services/crm/error/types";
import { getOrders } from "../../clients/services/crm/merlin";
import { Order } from "../../clients/services/crm/merlin/types";
import { useAppSelector } from "../../state/hooks";

export const useGetMerlinOrders = () => {
  const cpn = useAppSelector((state) => state.user.cpn);
  return useQuery<Order[], AxiosError<SaversError>>(["orders", cpn], () =>
    cpn ? getOrders(cpn) : []
  );
};
