import moment from "moment";
import { useState } from "react";

import { useGetBankedCodes } from "../../hooks/queries/useGetBankedCodes";
import { ErrorMessage } from "../ErrorMessage";

import { CodeCalendarComponent } from "./component";

export const CodeCalendar = () => {
  const [rangeStartDate, setRangeStartDate] = useState(
    moment().startOf("month")
  );
  const [rangeEndDate, setRangeEndDate] = useState(moment().endOf("month"));
  const { isLoading, data, error } = useGetBankedCodes(
    rangeStartDate,
    rangeEndDate
  );

  if (error) {
    return <ErrorMessage axiosErrors={[error]} />;
  }

  return (
    <>
      <div className={isLoading ? "loading" : ""}>
        <CodeCalendarComponent
          bankedCodes={data || []}
          onRangeChange={(range: { start: Date; end: Date }) => {
            setRangeStartDate(moment(range.start));
            setRangeEndDate(moment(range.end));
          }}
        />
      </div>
    </>
  );
};
