import {
  AppBar,
  CircularProgress,
  createStyles,
  alpha,
  InputBase,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  IconButton,
  FormGroup,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { useOktaAuth } from "@okta/okta-react";
import { useState } from "react";
import { useIsFetching, useQueryClient } from "react-query";

import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { setMockMode } from "../../state/reducers/mock";
import { setUser } from "../../state/reducers/user";
import { isPreProdEnvironment } from "../../util/environment";
import Login from "../Login";
import Logout from "../Logout";

const CPN_LENGTH = 13;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block"
      }
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto"
      }
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    loadingIcon: {
      color: "white"
    },
    inputRoot: {
      color: "inherit"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch"
      }
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex"
      }
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none"
      }
    },
    toolbar: {
      justifyContent: "space-between"
    },
    toolbarLeft: {
      display: "flex",
      flexDirection: "row"
    },
    toolbarRight: {
      display: "flex",
      justifySelf: "right"
    },
    divider: {
      padding: 10
    }
  })
);

const CrmAppBar = () => {
  const classes = useStyles();
  const isMockMode = useAppSelector((state) => state.mock.enabled);
  const environment = useAppSelector((state) => state.config?.environment);
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const fetching = useIsFetching();
  const queryClient = useQueryClient();
  const isEnabledForUse = authState.isAuthenticated || isMockMode;
  const [cpn, setCpn] = useState("");

  let inputIcon = <SearchIcon data-testid="search-icon" />;

  if (cpn?.length === CPN_LENGTH) {
    inputIcon = <CheckIcon data-testid="success-icon" />;
  } else if (cpn?.length > 0) {
    inputIcon = <ErrorIcon data-testid="error-icon" />;
  }

  if (fetching > 0) {
    inputIcon = (
      <CircularProgress
        size={18}
        className={classes.loadingIcon}
        data-testid="loading-icon"
      />
    );
  }

  return (
    <AppBar position="sticky">
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarLeft}>
          <Typography variant="h6" className={classes.title} noWrap>
            Customer Data
          </Typography>
          <div className={classes.search} data-testid="search">
            <div className={classes.searchIcon}>{inputIcon}</div>
            <InputBase
              placeholder="Search for CPN"
              value={cpn}
              onChange={(event) => {
                setCpn(event.target.value);
                if (event.target.value.length === CPN_LENGTH) {
                  dispatch(setUser({ cpn: event.target.value }));
                }
              }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{
                "aria-label": "search",
                "data-testid": "cpn-search-input"
              }}
              disabled={!isEnabledForUse}
            />
          </div>
          <IconButton
            size={"small"}
            color="inherit"
            onClick={() => queryClient.invalidateQueries()}
            data-testid="refresh-button"
          >
            <RefreshIcon />
          </IconButton>
        </div>
        <div className={classes.toolbarRight}>
          {isPreProdEnvironment(environment!) && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isMockMode}
                    value={isMockMode}
                    onChange={() =>
                      dispatch(setMockMode({ enabled: !isMockMode }))
                    }
                    data-testid="mock-mode-switch"
                  />
                }
                label="Mock"
              />
            </FormGroup>
          )}
          <div className={classes.divider}>|</div>
          {authState.isAuthenticated ? <Logout /> : <Login />}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default CrmAppBar;
