import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { bankCode } from "../../clients/services/crm/loyalty";
import { useAppSelector } from "../../state/hooks";
import { setNotification } from "../../state/reducers/notification";

export const useBankCode = (code: string, date: moment.Moment) => {
  const cpn = useAppSelector((state) => state.user.cpn);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    "bankCode",
    () =>
      cpn
        ? bankCode(cpn, code, date.format("YYYY-MM-DD"))
        : new Promise(() => null),
    {
      onSuccess: () => {
        dispatch(
          setNotification({
            message: "Code banked successfully.",
            severity: "success"
          })
        );
        queryClient.invalidateQueries("bankedCodes");
      },
      onError: (error: AxiosError) => {
        dispatch(
          setNotification({
            message: error?.response?.data.errorMessage,
            severity: "error"
          })
        );
      }
    }
  );
};
