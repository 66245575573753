import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  TextField
} from "@material-ui/core";
import { useState } from "react";

import { useCheckCode } from "../../hooks/queries/useCheckCode";
import { ErrorMessage } from "../ErrorMessage";

import CheckCode from "./component";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "50%",
    marginBottom: 8
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary
  }
}));

export default () => {
  const [code, setCode] = useState("");
  const { isLoading, data, error, refetch } = useCheckCode(code);
  const classes = useStyles();
  const inputField = (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={6}>
        <TextField
          value={code}
          onChange={(e) => setCode(e.target.value.toUpperCase())}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              refetch();
            }
          }}
          variant={"outlined"}
          label={"Code to check"}
          size={"small"}
          inputProps={{ "data-testid": "code-input" }}
          fullWidth={true}
        ></TextField>
      </Grid>
      <Grid item xs={6}>
        <Button
          type={"submit"}
          variant="contained"
          onClick={() => refetch()}
          data-testid="check-button"
          fullWidth={true}
        >
          Check Code
        </Button>
      </Grid>
    </Grid>
  );
  if (isLoading) {
    return (
      <>
        <div>{inputField}</div>
        <div>
          <CircularProgress />
        </div>
      </>
    );
  }

  if (error) {
    return <ErrorMessage axiosErrors={[error]} />;
  }

  return (
    <>
      <div>{inputField}</div>
      <div>
        <CheckCode checkCodeResponse={data} />
      </div>
    </>
  );
};
