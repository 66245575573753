import { CircularProgress } from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";

import CustomerData from "../../components/CustomerData";

const Home = () => {
  const { authState } = useOktaAuth();

  if (authState.isPending) {
    return <CircularProgress data-testid={"loading"} />;
  }

  return <CustomerData />;
};

export default Home;
