import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  TableBody,
  Checkbox
} from "@material-ui/core";
import moment from "moment-timezone";

import { optInPromotion } from "../../clients/services/crm/promotion";
import {
  PromotionFullInfo,
  RegisteredPromotion,
  Status
} from "../../clients/services/crm/promotion/types";
import TableMenu from "../TableMenu";

interface Props {
  promotions: PromotionFullInfo[];
}

const PromotionsTable = ({ promotions }: Props) => {
  const showTableMenu = ({ cpn, optedIn, status }: PromotionFullInfo) =>
    cpn && !optedIn && status !== Status.EXPIRED;

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>Promotion Name</TableCell>
            <TableCell>Opted in?</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Code Collect Start Date</TableCell>
            <TableCell>Code Collect End Date</TableCell>
            <TableCell>Redemption End Date</TableCell>
            <TableCell>Codes Banked</TableCell>
            <TableCell>Codes Required</TableCell>
            <TableCell>Customer can redeem?</TableCell>
            <TableCell>Customer has redeemed?</TableCell>
            <TableCell>Last Redemption Date</TableCell>
            <TableCell>Multiple Redemptions</TableCell>
            <TableCell>Opt in</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {promotions.reverse().map((promotion, index) => (
            <TableRow key={`promotion-${promotion.code}`}>
              <TableCell data-testid={`promotionCodeTestId${index}`}>
                {promotion.code}
              </TableCell>
              <TableCell data-testid={`promotionNameTestId${index}`}>
                {promotion.name}
              </TableCell>
              <TableCell>
                <Checkbox
                  data-testid={`promotionCheckboxTestId${index}`}
                  checked={promotion.optedIn}
                  disabled
                />
              </TableCell>
              <TableCell data-testid={`promotionStatusTestId${index}`}>
                {promotion.status}
              </TableCell>
              <TableCell
                data-testid={`promotionCodeCollectStartDateTestId${index}`}
              >
                {moment(promotion.codeCollectStartDate).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell
                data-testid={`promotionCodeCollectEndDateTestId${index}`}
              >
                {moment(promotion.codeCollectEndDate).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell data-testid={`promotionRedemtionEndDateTest${index}`}>
                {moment
                  .tz(promotion.redemptionEndDateTime, "UTC")
                  .tz(moment.tz.guess())
                  .format("YYYY-MM-DD HH:mm:ss")}
              </TableCell>
              <TableCell data-testid={`promotionCodesCollectedTestId${index}`}>
                {promotion.codesCollected !== undefined
                  ? promotion.codesCollected
                  : ""}
              </TableCell>
              <TableCell
                data-testid={`promotionRedemptionThresholdTestId${index}`}
              >
                {promotion.redemptionThreshold}
              </TableCell>
              <TableCell>
                <Checkbox
                  data-testid={`promotionIsEligibleToRedeemTestId${index}`}
                  checked={promotion.isEligibleToRedeem}
                  disabled
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  data-testid={`promotionHasRedeemedTestId${index}`}
                  checked={promotion.hasRedeemed}
                  disabled
                />
              </TableCell>
              <TableCell
                data-testid={`promotionRedeemedDateTimeTestId${index}`}
              >
                {promotion.redeemedDateTime
                  ? moment
                      .tz(promotion.redeemedDateTime, "UTC")
                      .tz(moment.tz.guess())
                      .format("YYYY-MM-DD HH:mm:ss")
                  : ""}
              </TableCell>
              <TableCell>
                <Checkbox
                  data-testid={`promotionAllowMultipleRedemptionTestId${index}`}
                  checked={promotion.allowMultipleRedemption}
                  disabled
                />
              </TableCell>
              <TableCell>
                {showTableMenu(promotion) ? (
                  <TableMenu<RegisteredPromotion>
                    onPress={() =>
                      optInPromotion(promotion.cpn!, promotion.code)
                    }
                    validateQuery="registeredPromotions"
                    propToValidate={promotion.cpn}
                    notificationMessage="Opt in successful"
                    menuItemText="Opt in"
                    dialogContentText={`Are you sure you want to register the customer with cpn ${promotion.cpn} for promotion with code ${promotion.code}?`}
                    dialogTitle="Opt in"
                    dialogButtonText="Opt in"
                  />
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default PromotionsTable;
