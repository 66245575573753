import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import { AxiosError } from "axios";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { useAppDispatch } from "../../state/hooks";
import { setNotification } from "../../state/reducers/notification";

interface Props<T> {
  onPress: () => Promise<T>;
  validateQuery: string;
  propToValidate: any;
  notificationMessage: string;
  menuItemText: string;
  dialogContentText: string;
  dialogTitle: string;
  dialogButtonText: string;
  disabledCondition?: boolean;
}
const TableMenu = <T,>({
  onPress,
  validateQuery,
  propToValidate,
  notificationMessage,
  menuItemText,
  dialogContentText,
  dialogTitle,
  dialogButtonText,
  disabledCondition = false
}: Props<T>) => {
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  const { mutate: mutation, isLoading } = useMutation(onPress, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries([validateQuery, propToValidate]);
      dispatch(
        setNotification({ message: notificationMessage, severity: "success" })
      );
    },
    onError: (error: AxiosError) => {
      dispatch(
        setNotification({
          message:
            error.response?.status === 401
              ? error.response.data.errorMessage
              : error.message,
          severity: "error"
        })
      );
    }
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
    handleMenuClose();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handle = () => {
    mutation();
    handleDialogClose();
  };
  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleButtonClick}
        disabled={isLoading}
        data-testid="menu-button"
      >
        {isLoading ? (
          <CircularProgress
            size={24}
            style={{ color: "rgba(0, 0, 0, 0.54)" }}
            data-testid="loading-indicator"
          />
        ) : (
          <MoreIcon />
        )}
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        data-testid="menu"
      >
        <MenuItem
          onClick={handleClickOpen}
          disabled={disabledCondition}
          data-testid="menu-item"
        >
          {menuItemText}
        </MenuItem>
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="menu-dialog"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handle}
            color="primary"
            data-testid="menu-dialog-button"
          >
            {dialogButtonText}
          </Button>
          <Button
            onClick={handleDialogClose}
            color="primary"
            data-testid="cancel-dialog-button"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default TableMenu;
