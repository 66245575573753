import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { useState } from "react";

interface Props {
  title: string;
  description: string;
  actionLabel: string;
  action: () => void;
  isLoading: boolean;
  testIdPrefix?: string;
  children: (dialogOpen: any) => React.ReactNode;
}

const ConfirmationDialog = ({
  title,
  description,
  actionLabel,
  action,
  isLoading,
  testIdPrefix = "confirm",
  children
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleAction = () => {
    action();
    handleDialogClose();
  };

  return (
    <>
      {children(handleDialogOpen)}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid={`${testIdPrefix}-dialog`}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isLoading && (
            <CircularProgress
              size={24}
              style={{ color: "rgba(0, 0, 0, 0.54)" }}
              data-testid={`${testIdPrefix}-loading-indicator`}
            />
          )}
          <Button
            onClick={handleAction}
            color="primary"
            disabled={isLoading}
            data-testid={`${testIdPrefix}-action-dialog-button`}
          >
            {actionLabel}
          </Button>
          <Button
            onClick={handleDialogClose}
            color="primary"
            data-testid={`${testIdPrefix}-cancel-dialog-button`}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
