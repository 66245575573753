import { useGetBankedCodes } from "../../hooks/queries/useGetBankedCodes";
import { useGetRaffleTickets } from "../../hooks/queries/useGetRaffleTickets";
import { ErrorMessage } from "../ErrorMessage";

import DailyCodeSummary from "./component";

interface Props {
  date: moment.Moment;
  onClose: () => void;
  onBack: () => void;
  onForward: () => void;
}

export default ({ date, onClose, onBack, onForward }: Props) => {
  const {
    data: dataBankedCodes,
    error: errorBankedCodes,
    isLoading: isLoadingBankedCodes
  } = useGetBankedCodes(date, date);
  const {
    data: dataRaffleTickets,
    error: errorRaffleTickets,
    isLoading: isLoadingRaffleTickets
  } = useGetRaffleTickets(date);

  if (errorBankedCodes || errorRaffleTickets) {
    return (
      <ErrorMessage axiosErrors={[errorBankedCodes, errorRaffleTickets]} />
    );
  }

  return (
    <DailyCodeSummary
      date={date}
      onClose={onClose}
      onBack={onBack}
      onForward={onForward}
      bankedCode={dataBankedCodes?.find((x) => !!x)}
      raffleTicket={dataRaffleTickets?.find((x) => x)}
      loading={isLoadingBankedCodes || isLoadingRaffleTickets}
    />
  );
};
