import { CircularProgress } from "@material-ui/core";
import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { getCodeBalance } from "../../clients/services/crm/loyalty";
import { getRaffleCurrentMonthTicketCount } from "../../clients/services/crm/raffle";
import { useAppSelector } from "../../state/hooks";
import { ErrorMessage } from "../ErrorMessage";

import CodeBalancePanel from "./component";

export default () => {
  const cpn = useAppSelector((state) => state.user.cpn);
  const {
    isLoading: isLoadingCodeBalance,
    data: dataCodeBalance,
    error: errorCodeBalance
  } = useQuery(["codeBalance", cpn], () =>
    cpn ? getCodeBalance(cpn) : undefined
  );

  const {
    isLoading: isLoadingRaffleTicketCount,
    data: dataRaffleTicketCount,
    error: errorRaffleTicketCount
  } = useQuery(["getRaffleCurrentMonthTicketCount", cpn], () =>
    cpn ? getRaffleCurrentMonthTicketCount(cpn) : undefined
  );

  if (isLoadingCodeBalance || isLoadingRaffleTicketCount) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  if (errorCodeBalance || errorRaffleTicketCount) {
    return (
      <ErrorMessage
        axiosErrors={[
          errorCodeBalance as AxiosError,
          errorRaffleTicketCount as AxiosError
        ]}
      />
    );
  }

  return (
    <CodeBalancePanel
      codeBalance={dataCodeBalance}
      raffleTicketCount={dataRaffleTicketCount}
    />
  );
};
