import {
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  TextField
} from "@material-ui/core";
import { AxiosError } from "axios";
import { useState } from "react";
import { useQueries } from "react-query";

import {
  getPayoutMethods,
  getWalletBalance
} from "../../clients/services/crm/wallet";
import { PayoutMethod } from "../../clients/services/crm/wallet/types";
import { useCreatePayoutMethod } from "../../hooks/queries/useCreatePayoutMethod";
import { useAppSelector } from "../../state/hooks";
import { ErrorMessage } from "../ErrorMessage";
import { LoadingButton } from "../LoadingButton";

import PayoutMethodsTable from "./component";

const useStyles = makeStyles(() =>
  createStyles({
    gridBoxSpace: {
      padding: "8px"
    }
  })
);

export default () => {
  const cpn = useAppSelector((state) => state.user.cpn);
  const [payoutMethods, walletBalance] = useQueries([
    {
      queryKey: ["getPayoutMethods", cpn],
      queryFn: () => (cpn ? getPayoutMethods(cpn) : [])
    },
    {
      queryKey: ["getWalletBalance", cpn],
      queryFn: () => (cpn ? getWalletBalance(cpn) : [])
    }
  ]);
  const [accountHolderName, setAccountHolderName] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const { mutate: createPayoutMethodMutation, isLoading: isLoadingResponse } =
    useCreatePayoutMethod(accountHolderName, accNumber, sortCode);

  const classes = useStyles();
  if (payoutMethods.isLoading || walletBalance.isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (payoutMethods.error || walletBalance.error) {
    return (
      <ErrorMessage
        axiosErrors={[
          payoutMethods.error as AxiosError,
          walletBalance.error as AxiosError
        ]}
      />
    );
  }

  const payoutMethodsData = payoutMethods.data as PayoutMethod[];

  if (payoutMethodsData && payoutMethodsData.length > 0) {
    return (
      <>
        <PayoutMethodsTable
          cpn={cpn!}
          balance={walletBalance.data as number}
          methods={payoutMethodsData}
          refetchMethods={payoutMethods.refetch}
          refetchBalance={walletBalance.refetch}
        />
      </>
    );
  }

  return (
    <>
      <Grid item xs={6} className={classes.gridBoxSpace}>
        <TextField
          variant="outlined"
          size="small"
          placeholder={"Account holders name"}
          value={accountHolderName}
          onChange={(e) => setAccountHolderName(e.target.value)}
          fullWidth={true}
          inputProps={{ "data-testid": "acc-holder-name-input" }}
        ></TextField>
      </Grid>
      <Grid item xs={6} className={classes.gridBoxSpace}>
        <TextField
          variant="outlined"
          size="small"
          placeholder={"Sort Code"}
          value={sortCode}
          onChange={(e) => setSortCode(e.target.value)}
          fullWidth={true}
          inputProps={{ "data-testid": "sort-code-input" }}
        ></TextField>
      </Grid>
      <Grid item xs={6} className={classes.gridBoxSpace}>
        <TextField
          variant="outlined"
          size="small"
          placeholder={"Account number"}
          value={accNumber}
          onChange={(e) => setAccNumber(e.target.value)}
          fullWidth={true}
          inputProps={{ "data-testid": "acc-number-input" }}
        ></TextField>
      </Grid>
      <Grid item xs={6} className={classes.gridBoxSpace}>
        <LoadingButton
          loading={isLoadingResponse}
          onClick={() => {
            createPayoutMethodMutation(undefined, {
              onSuccess: () => {
                payoutMethods.refetch();
              }
            });
          }}
          label={"Create new BACS payout method"}
          testId={"create-payout-button"}
        />
      </Grid>
    </>
  );
};
