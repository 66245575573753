import config, { setConfig } from "./config";
import mock, { setMockMode } from "./mock";
import notification, { setNotification } from "./notification";
import security, { setSecurity } from "./security";
import user, { setUser } from "./user";

export const actions = {
  setConfig,
  setMockMode,
  setNotification,
  setUser,
  setSecurity
};

const reducers = {
  config,
  mock,
  notification,
  user,
  security
};

export default reducers;
