import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  TableBody,
  makeStyles,
  createStyles
} from "@material-ui/core";
import moment from "moment";

import { refundOrder } from "../../clients/services/crm/merlin";
import { Order, Status } from "../../clients/services/crm/merlin/types";
import TableMenu from "../TableMenu";

interface Props {
  orders: Order[];
}
const useStyles = makeStyles(() =>
  createStyles({
    nestedTableCell: {
      borderBottomWidth: "0px"
    }
  })
);

const OrderTable = ({ orders }: Props) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Attraction</TableCell>
            <TableCell>Purchase Date</TableCell>
            <TableCell style={{ padding: "0px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="33%" className={classes.nestedTableCell}>
                      Purchased Variant
                    </TableCell>
                    <TableCell width="33%" className={classes.nestedTableCell}>
                      Quantity × Price
                    </TableCell>
                    <TableCell width="33%" className={classes.nestedTableCell}>
                      Ticket Date &amp; Time
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableCell>
            <TableCell style={{ padding: "0px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="33%" className={classes.nestedTableCell}>
                      Payment Type
                    </TableCell>
                    <TableCell width="33%" className={classes.nestedTableCell}>
                      Payment Amount
                    </TableCell>
                    <TableCell width="33%" className={classes.nestedTableCell}>
                      Payment Status
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableCell>
            <TableCell>Order Total</TableCell>
            <TableCell>Order Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow key={`order-${order.id}`}>
              <TableCell>{order.id}</TableCell>
              <TableCell>{order.product.title}</TableCell>
              <TableCell>{order.attraction.description}</TableCell>
              <TableCell>
                {moment(order.orderDate).format("YYYY-MM-DD HH:mm:ss")}
              </TableCell>
              <TableCell>
                <Table>
                  <TableBody>
                    {order.orderLines.map((orderLine) => (
                      <TableRow key={`orderline-${orderLine.id}`}>
                        <TableCell
                          width="33%"
                          className={classes.nestedTableCell}
                        >
                          {orderLine.variant.description}
                        </TableCell>
                        <TableCell
                          width="33%"
                          className={classes.nestedTableCell}
                        >
                          {orderLine.quantity} × £
                          {orderLine.variant.price.toFixed(2)}
                        </TableCell>
                        <TableCell
                          width="33%"
                          className={classes.nestedTableCell}
                        >
                          {orderLine.startDate} {orderLine.startTime}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
              <TableCell>
                <Table>
                  <TableBody>
                    {order.orderPayments?.map((orderPayment) => (
                      <TableRow key={`payment-${orderPayment.id}`}>
                        <TableCell
                          width="33%"
                          className={classes.nestedTableCell}
                        >
                          {orderPayment.type}
                        </TableCell>
                        <TableCell
                          width="33%"
                          className={classes.nestedTableCell}
                        >
                          £{orderPayment.amount.toFixed(2)}
                        </TableCell>
                        <TableCell
                          width="33%"
                          className={classes.nestedTableCell}
                        >
                          {orderPayment.status}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
              <TableCell>£{order.orderTotal.toFixed(2)}</TableCell>
              <TableCell>{order.status}</TableCell>
              <TableCell>
                <TableMenu<Order>
                  onPress={() => refundOrder(order.id, order.cpn)}
                  validateQuery="orders"
                  propToValidate={order.cpn}
                  notificationMessage="Refund successful"
                  menuItemText="Refund"
                  dialogContentText="Are you sure you want to refund this order?"
                  dialogTitle="Refund order"
                  dialogButtonText="Refund"
                  disabledCondition={order.status !== Status.COMPLETE}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default OrderTable;
