import { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";
import client from "../../base";

import {
  BankedCode,
  CheckCodeResponse,
  CodeBalance,
  CodeBankingAttempt,
  SupportCode
} from "./types";

const baseUrl = staticConfig.servicesUrls?.crmService;

export const getCodeBankingAttempts = async (
  cpn: string,
  numberOfAttempts: string
) => {
  const url = `${baseUrl}/loyalty/code-banking-attempts?cpn=${cpn}&numberOfAttempts=${numberOfAttempts}`;

  const response: AxiosResponse<CodeBankingAttempt[]> = await client.get(url);
  return response.data;
};

export const getCodeBalance = async (cpn: string) => {
  const url = `${baseUrl}/loyalty/code-balance?cpn=${cpn}`;

  const response: AxiosResponse<CodeBalance> = await client.get(url);
  return response.data;
};

export const getSupportCode = async (publicationDate: string) => {
  const url = `${baseUrl}/loyalty/support-code`;
  const response: AxiosResponse<SupportCode> = await client.post(url, {
    publicationDate
  });
  return response.data;
};

export const checkCode = async (code: string) => {
  const url = `${baseUrl}/loyalty/check-code?code=${code}`;

  const response: AxiosResponse<CheckCodeResponse> = await client.get(url);
  return response.data;
};

export const getBankedCodes = async (
  cpn: string,
  fromDate: string,
  toDate: string
) => {
  const url = `${baseUrl}/loyalty/banked-codes?cpn=${cpn}&fromDate=${fromDate}&toDate=${toDate}`;

  const response: AxiosResponse<BankedCode[]> = await client.get(url);
  return response.data;
};

export const bankCode = async (
  cpn: string,
  code: string,
  publicationDate: string
) => {
  const url = `${baseUrl}/loyalty/bank-code?cpn=${cpn}`;

  const response: AxiosResponse<BankedCode> = await client.post(url, {
    publicationDate,
    code
  });
  return response.data;
};
