import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  lighten,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../state/hooks";
import { setSecurity } from "../../state/reducers/security";
import CheckCode from "../CheckCode";
import CodeBalance from "../CodeBalance";
import CodeBankingAttemptsTable from "../CodeBankingAttempts";
import { CodeCalendar } from "../CodeCalendar";
import CrmAppBar from "../CrmAppBar";
import CustomerDetailsTable from "../CustomerDetails";
import MfaTable from "../MfaTable";
import OrderTable from "../OrderTable";
import PayoutMethods from "../PayoutMethods";
import PromotionsTable from "../PromotionsTable";
import WalletTable from "../Wallet";

import useSetCpn from "./useSetCpn";

const useStyles = makeStyles(() =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    scrollContainer: {
      maxHeight: "564px",
      overflow: "scroll",
      flexFlow: "column",
      paddingTop: 18,
      backgroundColor: "#f8f8f8"
    }
  })
);

const accordions = [
  {
    title: "Blacklist customer",
    testId: "customer-details-accordion",
    Component: CustomerDetailsTable
  },
  {
    title: "Code Balance",
    testId: "codes-accordion",
    Component: CodeBalance
  },
  {
    title: "Code History",
    testId: "code-history-accordion",
    Component: CodeCalendar
  },
  {
    title: "Code Check",
    testId: "check-code-accordion",
    Component: CheckCode,
    cpnIndependent: true
  },
  {
    title: "Code Banking",
    testId: "code-banking-attempts-accordion",
    Component: CodeBankingAttemptsTable
  },

  {
    title: "Wallet",
    testId: "wallet-accordion",
    Component: WalletTable
  },
  {
    title: "Payout Methods",
    testId: "payout-methods-accordion",
    Component: PayoutMethods
  },
  {
    title: "Merlin Orders",
    testId: "merlin-orders-accordion",
    Component: OrderTable
  },
  {
    title: "Promotions",
    testId: "promotions-accordion",
    Component: PromotionsTable
  },
  {
    title: "2-step verification",
    testId: "mfa-methods-accordion",
    Component: MfaTable
  }
];

const CustomerData = () => {
  const classes = useStyles();
  useSetCpn();
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSecurity({ token: authState.accessToken?.value }));
  }, [authState.accessToken?.value]);
  const cpn = useAppSelector((state) => state.user.cpn);
  const isMockMode = useAppSelector((state) => state.mock.enabled);
  return (
    <div className={classes.grow}>
      <CrmAppBar />
      {accordions.map(({ title, testId, Component, cpnIndependent }, index) => (
        <Accordion
          key={index}
          data-testid={testId}
          disabled={
            (!authState?.isAuthenticated && !isMockMode) ||
            (!cpn && !cpnIndependent)
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={
              makeStyles((theme: Theme) =>
                createStyles({
                  accordion: {
                    backgroundColor: lighten(
                      theme.palette.primary.main,
                      (1 / (accordions.length + 2.5)) * (index + 2) // produces a gradient of colours
                    )
                  }
                })
              )().accordion
            }
          >
            <Typography
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.scrollContainer}>
            <Component />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default CustomerData;
