import { configureStore } from "@reduxjs/toolkit";

import { isLocalEnvironment } from "../util/environment";

import reducers from "./reducers";

const store = configureStore({
  devTools: isLocalEnvironment(),
  reducer: reducers
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
