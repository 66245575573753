import { CircularProgress } from "@material-ui/core";
import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { getEnrollments } from "../../clients/services/crm/auth0";
import { useAppSelector } from "../../state/hooks";
import { ErrorMessage } from "../ErrorMessage";

import MfaTable from "./component";

export default () => {
  const cpn = useAppSelector((state) => state.user.cpn);

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["enrollments", cpn],
    () => (cpn ? getEnrollments(cpn) : [])
  );

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage axiosErrors={[error as AxiosError]} />;
  }

  if (data && data.length > 0) {
    return <MfaTable enrollments={data} refetchMethods={refetch} />;
  }

  return <div>No enrollment found</div>;
};
