import { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";
import client from "../../base";

import { CurrentMonthTicketCount, RaffleEntry, RaffleTicket } from "./types";

const baseUrl = staticConfig.servicesUrls?.crmService;

export const getRaffleCurrentMonthTicketCount = async (cpn: string) => {
  const url = `${baseUrl}/raffle/current-month-ticket-count?cpn=${cpn}`;

  const response: AxiosResponse<CurrentMonthTicketCount> = await client.get(
    url
  );
  return response.data;
};

export const getRaffleEntries = async (
  cpn: string,
  fromDate: string,
  toDate: string
) => {
  const url = `${baseUrl}/raffle/raffle-entries?cpn=${cpn}&fromDate=${fromDate}&toDate=${toDate}`;

  const response: AxiosResponse<RaffleEntry[]> = await client.get(url);
  return response.data;
};

export const getRaffleTickets = async (
  cpn: string,
  fromDate: string,
  toDate: string
) => {
  const url = `${baseUrl}/raffle/raffle-tickets?cpn=${cpn}&fromDate=${fromDate}&toDate=${toDate}`;

  const response: AxiosResponse<RaffleTicket[]> = await client.get(url);
  return response.data;
};
