import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { SaversError } from "../../clients/services/crm/error/types";
import { checkCode } from "../../clients/services/crm/loyalty";
import { CheckCodeResponse } from "../../clients/services/crm/loyalty/types";

export const useCheckCode = (code: string) => {
  return useQuery<CheckCodeResponse | undefined, AxiosError<SaversError>>(
    ["checkCode"],
    () => {
      return code ? checkCode(code) : undefined;
    }
  );
};
