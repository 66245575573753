import merge from "lodash/merge";

import { getAppConfig } from "../clients";

import staticConfig from "./static";
import { Config } from "./types";

export let config: Config;

export const setupConfig = async () => {
  const appConfig = await getAppConfig();

  config = merge({}, staticConfig, appConfig);

  return config;
};
